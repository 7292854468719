import { FC, PropsWithChildren, useState } from 'react';
import { Button, ButtonProps } from '@mui/joy';
import { useSnackbar, useQueryParams } from '@shared/lib';
import { useBlockchain } from '@entities/blockchain';

export type MintButtonProps = ButtonProps & PropsWithChildren;

export const MintButton: FC<MintButtonProps> = ({
  children = 'Mint',
  sx,
  ...props
}) => {
  const snackbar = useSnackbar();
  const blockchain = useBlockchain();
  const queryParams = useQueryParams();
  const [isMinting, setIsMinting] = useState(false);

  const handleMint = async (): Promise<void> => {
    if (isMinting) return;
    setIsMinting(true);

    try {
      const hasMinted = await blockchain.hasMinted();
      if (hasMinted) {
        snackbar.success({
          message: 'You have already minted earlier.',
          autoHideDuration: 2000
        });
        setIsMinting(false);
        return;
      }

      const mintTokenId = queryParams.get('token') ?? 0;
      await blockchain.mint(mintTokenId);
      snackbar.success({
        message: 'Successfully minted!',
        autoHideDuration: 3000
      });
    } catch (error: any) {

      let errorMessage = 'Not enough resources on your wallet';

      console.error(error.message);

      if (error?.message.includes('User denied transaction signature'))
        errorMessage = `You've decided to cancel the transaction. Feel free to initiate it again whenever you're ready.`
      else if (error?.message.includes('Insufficient funds'))
        errorMessage = 'Not enough resources on your wallet';
      else if (!errorMessage)
        errorMessage = 'Failed to mint the token. Please check your connection and try again.';
      snackbar.error({ autoHideDuration: 3000, message: errorMessage });

    } finally {
      setIsMinting(false);
    }
  };

  return (
    <Button
      color='secondary'
      sx={{
        width: 'fit-content',
        paddingBlock: 1.5,
        paddingInline: 4,
        zIndex: 16,
        ...sx
      }}
      onClick={handleMint}
      {...props}>
      {children}
    </Button>
  );
};
